import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordNormalized,
} from 'vue-router';
import Login from '@/views/login/login.vue';
import notFund from '@/views/error-page/404.vue';
import noPower from '@/views/noPower/index.vue';
import basics from '@/libs/basics.js';
import { mainStore } from '@/store';
import { routerMenu } from './getMenu.js';
import { tsTypes } from '@/libs/interfaceType';
import _ from 'lodash-es';
import page from '@/router/page';
import localRouter from '@/router/localRouter';
import http from '@/libs/service/http';
import api from '@/libs/api';
import { SysUserRole } from '@/libs/requestAddress';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    name: '404',
    component: notFund,
    meta: {
      title: '404',
    },
  },
  {
    path: '/noPower',
    name: 'noPower',
    component: noPower,
    meta: {
      title: '无权限',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  // ...localRouter,
];

const catchAll = {
  path: '/:pathMatch(.*)*', // 解决[Vue Router warn]: No match found for location with path "/xxx"警告
  component: notFund,
  hidden: true,
};

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});

// 不需要权限的菜单
export const promise: string[] = [];

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // 获取后台的动态接口域名
    const store = mainStore();
    if (!store.axiosBaseURL) {
      const res = await http.get(api.ossPath + `/${api.BODY_CODE}` + '/50');
      if (res?.url) store.axiosBaseURL = res.url + '/crm';
    }

    if (promise.indexOf(to.path as string) > -1) {
      next();
    } else if (!basics.Storage.isLogin()) {
      if (to.path !== '/login') {
        // 未登录且要跳转的页面不是登录页
        next({ path: '/login' });
      } else {
        next();
      }
    } else if (basics.Storage.isLogin() && to.path === '/login') {
      // 已登录且要跳转的页面是登录页
      next({ path: '/' });
    } else {
      saveNavTag(to);
      if (!store.init) {
        const obj: any = await routerMenu();
        const { routerList } = _.cloneDeep(obj);
        console.log(routerList);
        // 往router里面添加新的路由规则
        if (routerList.length) {
          routerList.push(catchAll);
          routerList.forEach((value: RouteRecordNormalized) => {
            router.addRoute(value);
          });
          store.menuList = routerList;
          const path = to.path === '/' ? routerList[0].children[0].path : to.path;
          next({ path: path, replace: true });
        } else {
          next('/noPower');
        }
      } else {
        await getUserPageAuth(to.path as string, store);
        next();
      }
    }
  }
);

interface keyValueParams {
  name: string;
  title: string;
}

function saveNavTag(route: RouteLocationNormalized) {
  if (route.name) {
    const obj = { name: route.name, title: route.meta.title };
    const arr = basics.Storage.getLocal('tagNavList') || [];
    const flag = arr.find((item: keyValueParams) => {
      return item.name === obj.name;
    });
    if (!flag) {
      arr.push(obj);
    }
    basics.Storage.setLocal('tagNavList', arr);
  }
}

// 用户当前菜单所有权限查询(按钮权限)
async function getUserPageAuth(path: string, store: any) {
  const name = path.replace(/\//g, '');
  if (name) {
    const data = await http.get(SysUserRole.auths, { name });
    store.userPageAuth = data;
  }
}

router.afterEach((route) => {
  window.scrollTo(0, 0);
});

export default router;
