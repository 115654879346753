<template>
  <el-dialog
    v-model="store.showNoticeDialog"
    :title="noticeContent?.title"
    width="620px"
    :before-close="handleClose"
    :modal="false"
    :fullscreen="isFullscreen"
    :modal-class="store.noticeDialogType === 1 ? 'rightBottomModal' : ''"
    :close-on-click-modal="false"
    @mouseover="closeTimeout"
    @mouseout="openTimeout"
  >
    <div class="fullscreen" @click="toggleFullscreen"
      ><el-icon><FullScreen /></el-icon
    ></div>
    <div
      :class="`system-info ${
        isFullscreen
          ? 'content-fullscreen'
          : store.noticeDialogType === 2
          ? 'is-half-fullscreen'
          : ''
      }`"
    >
      <ul>
        <!--   1 平台通知  2 系统分派通知 3 机构通知 4手动分派通知 -->
        <li
          v-if="noticeContent?.type === 1"
          class="info-system"
          @click="openNotice(noticeContent.id)"
        >
          <div class="info" v-html="noticeContent.content"></div>
        </li>
        <li
          v-if="noticeContent?.type === 2"
          class="info-custom"
          @click="openNotice(noticeContent.id)"
        >
          <div
            class="info"
            style="white-space: pre-wrap"
            v-if="noticeContent?.noticeOrderInfos && getContent(noticeContent.content)"
            >{{ getContent(noticeContent.content)[0] }}
            <el-link
              type="primary"
              :underline="false"
              v-for="(order, index) in noticeContent?.noticeOrderInfos"
              :key="order.userCapitalOrderId"
              v-show="index < 10"
              @click.stop="openUserName(order.userCapitalOrderId)"
            >
              {{ order.name }}</el-link
            ><span class="more" v-if="noticeContent?.noticeOrderInfos.length > 10"
              >...等{{ noticeContent?.noticeOrderInfos.length }}人</span
            >
            {{ getContent(noticeContent.content)[1] }}</div
          >
        </li>
        <li
          v-if="noticeContent.type === 3"
          class="info-notice"
          @click="openNotice(noticeContent.id)"
        >
          <div class="info" v-html="noticeContent.content"></div>
        </li>
        <li
          v-if="noticeContent?.type === 4"
          class="info-custom"
          @click="openNotice(noticeContent.id)"
        >
          <div
            class="info"
            style="white-space: pre-wrap"
            v-if="noticeContent?.noticeOrderInfos && getContent(noticeContent.content)"
          >
            {{ getContent(noticeContent.content)[0] }}
            <el-link
              type="primary"
              :underline="false"
              v-for="(order, index) in noticeContent?.noticeOrderInfos"
              :key="order.userCapitalOrderId"
              v-show="index < 10"
              @click.stop="openUserName(order.userCapitalOrderId)"
            >
              {{ order.name }}</el-link
            >
            <span class="more" v-if="noticeContent?.noticeOrderInfos.length > 10"
              >...等{{ noticeContent?.noticeOrderInfos.length }}人</span
            >
            {{ getContent(noticeContent.content)[1] }}
          </div>
        </li>
      </ul>
    </div>
    <template #footer>
      <div class="footer">
        <el-button type="primary" @click="handleClose">我知道了</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
  import http from '@/libs/service/http';
  import { CustomWarehouse, Notice } from '@/libs/requestAddress';
  import { mainStore } from '@/store';
  import { ElMessage } from 'element-plus';
  import { tsTypes } from '@/libs/interfaceType';

  const store = mainStore();

  const handleClose = () => {
    store.closeNoticeDialog();
  };

  const isFullscreen = ref<boolean>(false);
  const toggleFullscreen = () => {
    isFullscreen.value = !isFullscreen.value;
  };

  const timer = ref<number>(0);
  onMounted(() => {
    openTimeout();
    getNoticeDetail();
  });

  const noticeContent = ref<tsTypes.objAndAny>({});
  const getNoticeDetail = () => {
    console.log('getNoticeDetail===', store.noticeId);
    if (store.noticeId != -1) {
      http.get(Notice.detail + `/${store.noticeId}`).then((res) => {
        console.log(res);
        noticeContent.value = res;
      });
    }
  };
  const openTimeout = () => {
    // 系统公告自动弹出时计时5s后关闭
    if (store.noticeDialogType === 1) {
      timer.value = window.setTimeout(() => {
        console.log('关闭弹窗');
        store.closeNoticeDialog();
      }, 1000 * 5);
    }
  };

  // 获取拆分内容
  const getContent = (content: string) => {
    if (content) {
      return content.split('#{#user}');
    }
    return '';
  };

  const openNotice = (id: number) => {
    store.openNoticeDialog(true, 2);
    store.setNoticeId(id);
  };

  const openUserName = async (id: number) => {
    http.get(CustomWarehouse.checkAuth, { id }).then((res) => {
      store.openNoticeDialog(false);
      store.setShowNoticeUserDetail(true, id);
    });
  };

  const closeTimeout = () => {
    // 关闭自动关闭弹窗
    if (store.noticeDialogType === 1 && timer.value) {
      clearTimeout(timer.value);
    }
  };
  onUnmounted(() => {
    closeTimeout();
  });
</script>

<style scoped lang="less">
  .over-class {
    // 样式参见/styles/index.less
  }
  .is-half-fullscreen {
    .system-info {
      height: 300px !important;
    }
  }
  .system-info {
    height: 82px;
    font-size: 14px;
    line-height: 22px;
    overflow-y: auto;
    .info {
      margin: 8px 0 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      line-height: 24px;
      overflow: hidden;
      .info {
        /* 三行显示省略号 */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .el-link {
        position: relative;
        line-height: 20px;
        padding-bottom: 2px;
        & + .el-link {
          margin-left: 10px;
          &:after {
            content: '，';
            position: absolute;
            left: -10px;
            bottom: 5px;
          }
        }
      }
    }
    :deep(.info img) {
      max-width: 200px !important;
      height: auto;
    }
    &.content-fullscreen {
      height: auto;
      .info {
        display: unset;
        -webkit-box-orient: unset;
        -webkit-line-clamp: unset;
      }
    }
    &.is-half-fullscreen {
      height: 300px !important;
    }
  }
  .fullscreen {
    position: absolute;
    top: 24px;
    right: 50px;
    i:hover {
      color: #1677ff;
      cursor: pointer;
    }
  }
  .footer {
    text-align: center;
  }
</style>
