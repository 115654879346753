import { defineStore } from 'pinia';
import http from '@/libs/service/http';
import { Notice } from '@/libs/requestAddress';
import basics from '@/libs/basics';
import { tsTypes } from '@/libs/interfaceType';
interface FormModalRequestInfo {
  id?: number;
  type?: string;
}
export const mainStore = defineStore('main', {
  state: () => {
    return {
      init: false, // 加载状态
      menuList: [], // 菜单
      userPageAuth: [] as any[], // 页面权限
      breadCrumbList: [], // 顶部 面包屑 导航栏

      layoutWidth: 0, // 页面除菜单的宽度
      tableContentHeight: 0, // table高度

      /*
       * 全局图片预览
       * */
      previewPictureModalFlag: false,
      previewPictureModalImgSrc: [],
      previewPictureModalImgIndex: 0,

      /*
       * 全局form表单显隐
       * */
      formModalFlag: false,
      /*
       * 详情写成对象 方便部分详情的key可以自定义
       * */
      formModalRequestInfo: {} as FormModalRequestInfo,

      axiosBaseURL: '',
      showNoticeDialog: false, // 是否显示系统通知弹窗
      noticeDialogType: 1, // 系统通知弹窗类型: 1-右下角显示，2-中间显示
      noticeTimer: null as number | null, // 系统通知定时器
      noticeId: -1, // 通知Id
      noticeUserId: -1, // 通知内容里的userId
      showNoticeUserDetail: false, // 是否显示客户详情
      noticeConfig: {} as tsTypes.objAndAny, // 首页系统公告通知配置
    };
  },

  getters: {
    getLayoutWidth(state) {
      return state.layoutWidth as number;
    },
  },

  actions: {
    setBreadCrumb(to: any) {
      const matched = to.matched;
      const metaArr = matched.filter((item: any) => {
        return item.meta !== undefined;
      });
      const arr: Array<any> = [];
      metaArr.forEach((item: any) => {
        arr.push(item.meta.title);
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.breadCrumbList = arr;
    },
    previewPictureModalClick(to: boolean, url: string[] = [], index = 0) {
      this.previewPictureModalFlag = to;
      url = url.map((item: string) => {
        return item.indexOf('http') > -1 ? item : basics.Storage.getSession('imgBaseUrl') + item;
      });
      this.previewPictureModalImgSrc = Object.assign([], url);
      this.previewPictureModalImgIndex = index;
    },
    openNoticeDialog(data: boolean, type = 1) {
      this.showNoticeDialog = data;
      this.noticeDialogType = type;
    },
    closeNoticeDialog() {
      this.showNoticeDialog = false;
    },
    getNoticeList() {
      console.log('getNoticeList', !this.noticeTimer);

      if (basics.Storage.isLogin()) {
        // 当首页系统公告配置打开了右下角消息提醒时才请求
        this.noticeTimer = window.setInterval(async () => {
          if (Object.keys(this.noticeConfig).length === 0) {
            this.noticeConfig = await http.get(Notice.configDetail);
          }
          if (this.noticeConfig.popReminder) {
            http.post(Notice.getNotice).then((res) => {
              console.log('请求系统通知接口2222', res, this.noticeConfig);
              if (res) {
                this.showNoticeDialog = false;
                setTimeout(() => {
                  this.noticeId = res.id;
                  this.showNoticeDialog = true;
                  this.noticeDialogType = 1;
                }, 0);
              }
            });
          } else {
            window.clearInterval(this.noticeTimer as number);
          }
        }, 1000 * 30);
      }
    },
    setNoticeId(id: number) {
      this.noticeId = id;
    },
    setShowNoticeUserDetail(data: boolean, id = -1) {
      this.showNoticeUserDetail = data;
      this.noticeUserId = id;
    },
    setNoticeConfig(data: tsTypes.objAndAny) {
      this.noticeConfig = data;
    },
  },
});
