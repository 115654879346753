/*
 * TODO 此处引入项目logo图标、项目名称的图片
 *  public文件夹里面也要添加logo图标
 * */
import logo from '@/assets/images/logo.png';
import logoTitle from '@/assets/images/logo.png';

const runType = import.meta.env.APP_FLAG;

// 项目名称
const PROJECT_NAME = '荟信钱包';
// 公司名称
const COMPANY_NAME = '四川荟信数科网络科技有限公司';

/*
 * TODO 需要添加或修改以下内容
 *  SIGN_KEY 签名
 * */
const SIGN_KEY = '8ku4jazkh2nkab7ccuyksuxssa3348uzsfdeupr3mr4yby2p2ptetr2wvfkxnaxc';
const BODY_CODE = 'hxqb';

let ossPath = ''; // 动态域名OSS地址
/*
 * TODO 需要更换以下内容
 *  ossPath 动态域名地址
 * */
switch (runType) {
  case 'pro': // 线上环境
    ossPath = 'https://ttxs-pro.oss-cn-chengdu.aliyuncs.com/open/domain';
    break;
  case 'test': // 外网测试环境
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
    break;
  default: // 默认环境
    ossPath = 'https://ttxs-testing.oss-cn-chengdu.aliyuncs.com/open/domain';
    break;
}
export default {
  SIGN_KEY,
  BODY_CODE,
  ossPath,
  logo,
  logoTitle,
  PROJECT_NAME,
  COMPANY_NAME,
};
