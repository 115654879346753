import api from './api.js';

const TimeArr: any[] = [];
for (let i = 0; i < 24; i++) {
  TimeArr.push({ text: i + '时', value: `${i}` });
}
const adminConfig = {
  otherConfiguration: {
    /* 默认线上请求地址 */
    uploadExcelUrl: '/customer/uploadExcel', // excel上传地址
    pictureUploading: '/upload/img', // 图片上传地址
    picturePrefix: 'image', // 图片前缀
  },
  requestParameters: {
    // 请求参数
    request_SUCCESS: 0, // 请求成功状态
    request_FAIL: 1, // 请求失败状态
    request_TOKEN_ERROR: 401, // 登录失效
    request_SIGN_ERROR: 402, // 签名校验失败
    request_AUTH_ERROR: 403, // 访问受限
    request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
    request_BLACK: -10, // 黑名单
    request_NOT_PERMISSION: -20, // 无权限访问
    request_TIMEOUT: -30, // 请求超时
    request_VISIT_OUT: -40, // 访问次数受限
    request_NOT_FOUND: 404, // 资源不存在
  },
  store: {
    authorCacheName: 'userLoginInfo',
  },
  componentName: {
    MyTitle: 'MyTitle',
    MyInput: 'MyInput',
    MySelect: 'MySelect',
    MySelectCity: 'MySelectCity',
    MySelectCityLocal: 'MySelectCityLocal',
    MyCityLocalSearch: 'MyCityLocalSearch',
    MyTreeSelect: 'MyTreeSelect',
    MyTreeSelectCheck: 'MyTreeSelectCheck',
    MyRadio: 'MyRadio',
    MyRate: 'MyRate',
    MyCheckbox: 'MyCheckbox',
    ImgUpload: 'ImgUpload',
    MyTime: 'MyTime',
    MyDateTime: 'MyDateTime',
    MyRadioTime: 'MyRadioTime',
    MyCustomFormItem: 'MyCustomFormItem',
    MyGuideConfig: 'MyGuideConfig',
    MyText: 'MyText',
    MyCustomAddInputInputItem: 'MyCustomAddInputInputItem',
    MyInputLinkInputItem: 'MyInputLinkInputItem',
    MyCustomLabel: 'MyCustomLabel',
  },
  table: {
    buttonType: {
      // table按钮功能
      otherEvents: 'otherEvents', // 按钮其他事件
      delete: 'Delete', // 删除功能按钮
      render: 'Render', // 自定义内容按钮
    },
  },
  commonList: {
    banner: [
      { text: 'banner', value: 1 },
      { text: '运营弹窗', value: 2 },
      { text: '橱窗', value: 3 },
    ],
    jumpType: [
      { text: '内链', value: 1 },
      { text: '外链', value: 2 },
    ],
    productType: [
      { text: '网贷产品', value: 1 },
      { text: '助贷产品', value: 2 },
    ],
    processList: [
      { text: 'H5产品', value: 1 },
      { text: '联登产品', value: 2 },
      { text: 'api产品', value: 3 },
    ],
    isMobileSupport: [
      { text: '安卓', value: 'android' },
      { text: '苹果', value: 'ios' },
    ],
    applicationStatus: [
      { text: '申请成功', value: 1 },
      { text: '申请失败', value: 2 },
    ],
    // 渠道列表-状态
    channelStatus_: [
      {
        text: '下线',
        value: 0,
      },
      {
        text: '上线',
        value: 1,
      },
    ],
    // 上下架状态
    channelStatus_1: [
      {
        text: '下架',
        value: 0,
      },
      {
        text: '上架',
        value: 1,
      },
    ],
    // 显示状态
    ifHideList: [
      {
        text: '未隐藏',
        value: 0,
      },
      {
        text: '已隐藏',
        value: 1,
      },
    ],
    domainList: [
      {
        text: '留资渠道页面访问地址',
        value: 10,
      },
      {
        text: '注册下载渠道页面访问地址',
        value: 11,
      },
      {
        text: 'OSS文件',
        value: 20,
      },
      {
        text: 'App接口地址',
        value: 30,
      },
      {
        text: 'Web接口地址',
        value: 40,
      },
      {
        text: '管理端接口访问地址',
        value: 50,
      },
      {
        text: 'wap页面访问地址',
        value: 60,
      },
    ],
    domainType: [
      {
        text: '留资渠道',
        value: 10,
      },
      {
        text: '注册下载渠道',
        value: 11,
      },
    ],
    // 渠道列表-add - 城市限定
    cityEnable: [
      { text: '准入城市', value: 1 },
      { text: '禁用城市', value: 2 },
    ],
    // 选择是否
    isControl: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
    onlineStatus: [
      {
        text: '正常',
        value: 0,
      },
      {
        text: '停用',
        value: 1,
      },
    ],
    sex: [
      {
        text: '男',
        value: 1,
      },
      {
        text: '女',
        value: 0,
      },
    ],
    osDisplay: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: '展示Android',
        value: 1,
      },
      {
        text: '展示iOS',
        value: 2,
      },
    ],
    openDetail: [
      {
        text: '不开启',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    openStatus: [
      {
        text: '开启',
        value: 1,
      },
      {
        text: '关闭',
        value: 0,
      },
    ],
    limitCityType: [
      {
        text: '不限',
        value: 0,
      },
      {
        text: '准入',
        value: 1,
      },
      {
        text: '禁入',
        value: 2,
      },
    ],
    protocolType: [
      {
        text: '展示我司协议',
        value: 1,
      },
      {
        text: '展示我司协议+产品协议',
        value: 2,
      },
    ],
    nullParam: [
      {
        text: '不展示',
        value: 0,
      },
      {
        text: '展示',
        value: 1,
      },
    ],
    hoursList: TimeArr,
    recordType: [
      {
        text: '充值',
        value: 1,
      },
      {
        text: '退款',
        value: 2,
      },
      {
        text: '获客',
        value: 3,
      },
    ],
    payType: [
      {
        text: '手动充值',
        value: 0,
      },
      {
        text: '微信充值',
        value: 1,
      },
      {
        text: '支付宝充值',
        value: 2,
      },
      {
        text: '退款',
        value: -1,
      },
    ],
    aidCategory: [
      {
        text: '房主贷',
        value: 1,
      },
      {
        text: '车主贷',
        value: 2,
      },
      {
        text: '公积金贷',
        value: 3,
      },
      {
        text: '保单贷',
        value: 4,
      },
      {
        text: '信用贷',
        value: 5,
      },
      {
        text: '社保贷',
        value: 6,
      },
    ],
    shelfCycle: [
      {
        text: '每日上线',
        value: 1,
      },
      {
        text: '工作日上线（周一到周五）',
        value: 2,
      },
      {
        text: '周六周末上线',
        value: 3,
      },
      {
        text: '周六上线',
        value: 4,
      },
      {
        text: '周末上线',
        value: 5,
      },
    ],
    weekList: [
      {
        text: '周一',
        value: 1,
      },
      {
        text: '周二',
        value: 2,
      },
      {
        text: '周三',
        value: 3,
      },
      {
        text: '周四',
        value: 4,
      },
      {
        text: '周五',
        value: 5,
      },
      {
        text: '周六',
        value: 6,
      },
      {
        text: '周天',
        value: 7,
      },
    ],
    bannerList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心上',
        value: 2,
      },
    ],
    operationList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心',
        value: 2,
      },
    ],
    shopWindowList: [
      {
        text: '个人中心弹窗',
        value: 1,
      },
      {
        text: 'app退出拦截弹窗',
        value: 2,
      },
    ],
    implType: [
      {
        text: '阿里云短信',
        value: 1,
      },
      {
        text: '极光push实现',
        value: 2,
      },
      {
        text: '邮件实现',
        value: 3,
      },
    ],
    keyList: [
      { text: '埋点', value: 1 },
      { text: '参数', value: 2 },
    ],
    // 跟进状态
    followUpStatus: [
      { text: '待跟进', value: 1 },
      { text: '跟进中', value: 2 },
      { text: '预约到店', value: 3 },
      { text: '已到店', value: 4 },
      { text: '已签约', value: 5 },
      { text: '已进件', value: 6 },
      { text: '已过件', value: 7 },
      { text: '放款中', value: 8 },
      { text: '放款成功', value: 9 },
      { text: '放款失败', value: 10 },
      { text: '已收款', value: 11 },
      { text: '无效客户', value: 12 },
    ],
    // 星级
    starRating: [
      { text: '0星', value: 0 },
      { text: '1星', value: 1 },
      { text: '2星', value: 2 },
      { text: '3星', value: 3 },
      { text: '4星', value: 4 },
      { text: '5星', value: 5 },
      { text: '未标记', value: -1 },
    ],
    // 星级
    starRating2: [
      { text: '0星', value: 0 },
      { text: '1星', value: 1 },
      { text: '2星', value: 2 },
      { text: '3星', value: 3 },
      { text: '4星', value: 4 },
      { text: '5星', value: 5 },
      { text: '未标记', value: -1, hidden: true },
    ],
    // 月收入
    monthlyIncome: [
      { text: '2000元以下', value: 1 },
      { text: '2000~5000元', value: 2 },
      { text: '5000~10000元', value: 3 },
      { text: '10000元以上', value: 4 },
    ],
    // 贷款期限
    loanTerm: [
      { text: '6', value: 6 },
      { text: '9', value: 9 },
      { text: '12', value: 12 },
      { text: '24', value: 24 },
      { text: '36', value: 36 },
    ],
    // 学历
    education: [
      { text: '初中及以下', value: 1 },
      { text: '高中或中专', value: 2 },
      { text: '大专', value: 3 },
      { text: '本科', value: 4 },
      { text: '研究生及以上', value: 5 },
    ],
    // 社保
    socialInsurance: [
      { text: '无', value: 1 },
      { text: '缴纳未满6个月', value: 2 },
      { text: '缴纳6个月以上', value: 3 },
    ],
    // 保单
    insurancePolicy: [
      { text: '无保单', value: 1 },
      { text: '缴纳不足一年', value: 2 },
      { text: '缴纳1年以上', value: 3 },
      { text: '缴纳2年以上', value: 4 },
    ],
    // 有无
    has: [
      { text: '无', value: 1 },
      { text: '有', value: 2 },
    ],
    // 芝麻分
    sesameSeed: [
      { text: '无芝麻分', value: 1 },
      { text: '600分以下', value: 2 },
      { text: '600~650分', value: 3 },
      { text: '650~700分', value: 4 },
      { text: '700分以上', value: 5 },
    ],
    // 职业
    profession: [
      { text: '上班族', value: 1 },
      { text: '公务员/事业单位', value: 2 },
      { text: '私营业主（有营业执照）', value: 3 },
      { text: '个体户（无营业执照）', value: 4 },
      { text: '其他', value: 5 },
    ],
    // 工资发放形式
    salaryType: [
      { text: '银行卡', value: 1 },
      { text: '现金', value: 2 },
      { text: '自存', value: 3 },
    ],
    // 工龄
    workAge: [
      { text: '0~6个月', value: 1 },
      { text: '6~12个月', value: 2 },
      { text: '12个月以上', value: 3 },
    ],
    // 意向
    intention: [
      { text: '不需要', value: 1 },
      { text: '潜在', value: 2 },
      { text: '有需求', value: 3 },
      { text: '有强意向', value: 4 },
    ],
    auditStatus: [
      { text: '待提审', value: 0 },
      { text: '审核中', value: 1 },
      { text: '审核成功', value: 2 },
      { text: '审核失败', value: 3 },
    ],
    filterTypeList: [
      {
        text: '选中资质任意满足其一',
        value: '1',
      },
      {
        text: '选中资质任意满足其二',
        value: '2',
      },
      {
        text: '选中资质任意满足其三',
        value: '3',
      },
      {
        text: '选中资质任意满足其四',
        value: '4',
      },
      {
        text: '选中资质任意满足其五',
        value: '5',
      },
      {
        text: '选中资质任意满足其六',
        value: '6',
      },
      {
        text: '选中资质任意满足其七',
        value: '7',
      },
      {
        text: '选中资质任意满足其八',
        value: '8',
      },
      {
        text: '选中资质任意满足其九',
        value: '9',
      },
      {
        text: '选中资质任意满足其十',
        value: '10',
      },
    ],
    userStatus: [
      {
        text: '在职',
        value: 0,
      },
      {
        text: '离职',
        value: 1,
      },
    ],
    dataStatus: [
      {
        text: '未移交',
        value: 0,
      },
      {
        text: '已移交',
        value: 1,
      },
    ],
    releaseStatus: [
      {
        text: '未发布',
        value: 0,
      },
      {
        text: '已发布',
        value: 1,
      },
    ],
    operateType: [
      {
        text: '助贷产品',
        value: 1,
      },
      {
        text: '自动派发',
        value: 2,
      },
      {
        text: '员工管理',
        value: 3,
      },
      {
        text: '权限组管理',
        value: 4,
      },
    ],
    winCustomerTypeList: [
      { text: '每天一致', value: 1 },
      { text: '每天不同', value: 2 },
    ],
  },
};

export default adminConfig;
