<template>
  <div class="login">
    <div class="login-con">
      <div class="login-left">
        <img src="@/assets/images/login-bg.png" alt="" />
      </div>
      <div class="login-right">
        <div class="content">
          <div class="login-con-title">
            <img :src="api.logo" alt="" />
            <div>
              <h4>欢迎登录{{ api.PROJECT_NAME }}客户管理系统</h4>
              <p>{{ api.PROJECT_NAME }}在手，机构开单不用愁</p>
            </div>
          </div>
          <ul class="roleChoose">
            <li :class="state.form.isMaster === 0 ? 'active' : ''" @click="state.form.isMaster = 0"
              >员工登录</li
            >
            <li :class="state.form.isMaster === 1 ? 'active' : ''" @click="state.form.isMaster = 1"
              >管理员登录</li
            >
          </ul>
          <el-form
            ref="loginForm"
            :model="state.form"
            :rules="state.rules"
            @keydown.enter="submitForm(loginForm)"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="state.form.phone"
                placeholder="请输入手机号"
                :maxlength="11"
                :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
                :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
              >
                <template #prepend>
                  <el-icon :size="16" color="#EF8912">
                    <Iphone />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="smsCode">
              <el-input
                v-model="state.form.smsCode"
                placeholder="请输入验证码"
                :maxlength="4"
                :formatter="(value: string) => `${value}`.replace(/[^0-9-]+/, '')"
                :parser="(value: string) => value.replace(/[^0-9-]+/, '')"
              >
                <template #prepend>
                  <el-icon :size="16" color="#EF8912">
                    <Lock />
                  </el-icon>
                </template>
                <template #append>
                  <p style="cursor: pointer; color: #ef8912" v-show="state.show" @click="getSms()"
                    >获取验证码</p
                  >
                  <p style="cursor: pointer; color: #666" v-show="!state.show"
                    >{{ state.counts }}S后重新获取</p
                  >
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="submitForm(loginForm)"
                type="primary"
                style="width: 100%; margin-top: 10px"
                >登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ElMessage } from 'element-plus';
  import basics from '@/libs/basics';
  import http from '@/libs/service/http.js';
  import type { FormInstance } from 'element-plus';
  import { Auth } from '@/libs/requestAddress';
  import api from '@/libs/api';

  const emit = defineEmits(['on-success-valid']);
  const validatePhone = (rule: any, value: string, callback: any) => {
    if (!value) {
      return callback(new Error('手机号不能为空'));
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      callback('手机号格式不正确');
    } else {
      callback();
    }
  };
  let timer: any;
  const state = reactive({
    show: true,
    counts: 60,
    form: {
      isMaster: 1,
      phone: '',
      smsCode: '',
    },
    rules: {
      phone: [
        {
          validator: validatePhone,
        },
      ],
      smsCode: [
        {
          message: '验证码不能为空',
          required: true,
        },
      ],
    },
  });

  const loginForm = ref<FormInstance>();

  const submitForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid) => {
      if (valid) {
        let user = {
          phone: state.form.phone,
          smsCode: state.form.smsCode,
          isMaster: state.form.isMaster,
        };
        emit('on-success-valid', user);
      }
    });
  };
  const getSms = basics.throttle(function () {
    if (/^1[3456789]\d{9}$/.test(state.form.phone)) {
      let jsonPhone = { phone: state.form.phone };
      http.get(Auth.sendSms, jsonPhone).then(() => {
        getCode();
      });
    } else {
      ElMessage.error('手机号码错误');
    }
  }, 500);

  const getCode = () => {
    const TIME_COUNT = 60;
    if (!timer) {
      state.counts = TIME_COUNT;
      state.show = false;
      timer = setInterval(() => {
        if (state.counts > 0 && state.counts <= TIME_COUNT) {
          state.counts--;
        } else {
          state.show = true;
          clearInterval(timer as number);
          timer = null;
        }
      }, 1000);
    }
  };

  watch(
    () => state.form.isMaster,
    () => {
      state.form.phone = '';
      state.form.smsCode = '';
      state.show = true;
      clearInterval(timer as number);
      timer = null;
      console.log(timer);
    }
  );
</script>
<style lang="less">
  @import '@/views/login/login.less';
</style>
