import '@/assets/css/reset.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import directive from './directive';
import { createPinia } from 'pinia';
import '@/utils/resizeRem.js';

import ElementPlus from 'element-plus';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

import './styles/index.less';

const store = createPinia();

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router).use(store).use(directive).use(ElementPlus, { locale: zhCn }).mount('#app');
console.log(1);
